<script lang="ts">
  import { t } from '../i18n';
  import HistoryItem from '../components/HistoryItem.svelte';
  import ProjectItem from '../components/ProjectItem.svelte';

  $: historyItems = [
    {
      title: $t('main.history.cards.0.title'),
      url: 'https://www.toptal.com/',
      dateRange: $t('main.history.cards.0.dateRange'),
      content: $t('main.history.cards.0.content'),
      lines: [
        $t('main.history.cards.0.lines.0'),
        $t('main.history.cards.0.lines.1'),
        $t('main.history.cards.0.lines.2'),
      ],
    },
    {
      title: $t('main.history.cards.1.title'),
      url: 'https://www.timejobs.work/',
      dateRange: $t('main.history.cards.1.dateRange'),
      content: $t('main.history.cards.1.content'),
      lines: [
        $t('main.history.cards.1.lines.0'),
        $t('main.history.cards.1.lines.1'),
      ],
    },
    {
      title: $t('main.history.cards.2.title'),
      dateRange: $t('main.history.cards.2.dateRange'),
      content: $t('main.history.cards.2.content'),
      lines: [
        $t('main.history.cards.2.lines.0'),
        $t('main.history.cards.2.lines.1'),
        $t('main.history.cards.2.lines.2'),
        $t('main.history.cards.2.lines.3'),
        $t('main.history.cards.2.lines.4'),
      ],
    },
    {
      title: $t('main.history.cards.3.title'),
      url: 'https://mindtec.pe/',
      dateRange: $t('main.history.cards.3.dateRange'),
      content: $t('main.history.cards.3.content'),
      lines: [
        $t('main.history.cards.3.lines.0'),
        $t('main.history.cards.3.lines.1'),
        $t('main.history.cards.3.lines.2'),
        $t('main.history.cards.3.lines.3'),
        $t('main.history.cards.3.lines.4'),
        $t('main.history.cards.3.lines.5'),
        $t('main.history.cards.3.lines.6'),
        $t('main.history.cards.3.lines.7'),
        $t('main.history.cards.3.lines.8'),
      ],
    },
  ];
  $: customers = [
    {
      name: $t('main.customers.8'),
      logo: '/img/logos/toptal.png',
      url: 'https://www.toptal.com/',
    },
    {
      name: $t('main.customers.10'),
      logo: '/img/logos/classdojo.webp',
    },
    {
      name: $t('main.customers.9'),
      logo: '/img/logos/bcg.png',
    },
    {
      name: $t('main.customers.0'),
      logo: '/img/logos/timejobs.svg',
      url: 'https://www.timejobs.work/',
    },
    {
      name: $t('main.customers.1'),
      logo: '/img/logos/timejobs_app.png',
      url: 'https://play.google.com/store/apps/details?id=work.timejobs.jobbers',
    },
    {
      name: $t('main.customers.2'),
      logo: '/img/logos/edtlab.png',
      url: 'http://edtlab.pe/',
    },
    {
      name: $t('main.customers.3'),
      logo: '/img/logos/cigol.png',
      url: 'https://www.cigolinmobiliaria.com/',
    },
    {
      name: $t('main.customers.4'),
      logo: '/img/logos/acomo.png',
      url: 'https://acomo.com.pe/',
    },
    {
      name: $t('main.customers.5'),
      logo: '/img/logos/entel.png',
    },
    {
      name: $t('main.customers.6'),
      logo: '/img/logos/mindtec.png',
      url: 'https://tecnologistas.com/',
    },
    {
      name: $t('main.customers.7'),
      logo: '/img/logos/sportbalon.png',
      url: 'https://www.sportbalon.com/',
    },
  ];
  $: projects = [
    {
      title: $t('main.projects.cards.3.title'),
      content: $t('main.projects.cards.3.content'),
      imageUrl:
        'https://play-lh.googleusercontent.com/P_5ExsFjZub_DA8sOqHVK1kseEKxFJ3N9zuKJi_1850ZW2mSQ4pn7vUPzomgqaal7g=w240-h480-rw',
      url: 'https://play.google.com/store/apps/details?id=com.zegaru.sao_launcher',
    },
    {
      title: $t('main.projects.cards.0.title'),
      content: $t('main.projects.cards.0.content'),
      imageUrl: '/img/projects/cms.png',
      url: 'https://zegaru-cms.herokuapp.com/admin',
    },
    {
      title: $t('main.projects.cards.1.title'),
      content: $t('main.projects.cards.1.content'),
      imageUrl: '/img/projects/projects.png',
      url: 'https://projects.zegaru.com/',
    },
    {
      title: $t('main.projects.cards.2.title'),
      content: $t('main.projects.cards.2.content'),
      imageUrl: '/img/projects/crm.png',
      url: 'https://crm.zegaru.com/',
    },
  ];
</script>

<div
  class="flex-1 bg-gray-900 md:h-screen shadow-md px-2 md:px-8 pt-16 md:overflow-y-scroll scrollbar scroll-smooth"
>
  <div class="relative group">
    <div
      class="absolute -inset-1 bg-gradient-to-r from-sky-400 to-purple-600 rounded-sm blur opacity-75 group-hover:opacity-100 transition duration-800 group-hover:duration-500 animate-tilt"
    />
    <div
      class="shadow-lg bg-gray-700 rounded-sm bg-cover bg-center relative"
      style="background-image: url(/img/coding.png);"
    >
      <div class="relative py-8 px-4 md:p-8 bg-gray-600/75 rounded-sm">
        <h1 class="text-4xl md:text-5xl font-bold">
          {$t('main.greeting.title')}
        </h1>
        <div class="font-mono md:text-xl mt-4">
          &lt;<span class="text-primary-400">{$t('main.greeting.tag')}</span
          >&gt;
          <span class="mb-2 text-gray-100">
            {$t('main.greeting.start')} <br class="block md:hidden" />
            <span class="inline-flex items-center">
              <span
                class="animate-type will-change pt-2 overflow-x-hidden whitespace-nowrap text-brand-accent"
              >
                {$t('main.greeting.end')}
              </span>
              <span class="cursor inline-block" />
            </span>
          </span>
          &lt;<span class="text-primary-400">{$t('main.greeting.tag')}</span
          >/&gt;
        </div>
      </div>
    </div>
  </div>
  <div class="mt-12">
    <p class="text-xl font-bold">{$t('main.history.title')}</p>
    <div class="xl:flex mt-4">
      <div class="xl:max-w-lg 2xl:max-w-3xl">
        {#each historyItems as historyItem, i}
          <HistoryItem {...historyItem} index={i} />
        {/each}
      </div>
      <div
        class="flex-1 grid grid-cols-2 lg:gap-8 h-[fit-content] py-8 lg:p-8 mt-6 xl:mt-0"
      >
        {#each customers as customer}
          <a
            class="m-6 h-24 flex flex-col justify-center text-center items-center group col-span-1"
            href={customer.url}
            target="_blank"
          >
            <img
              src={customer.logo}
              alt={customer.name}
              class="max-h-24 mx-auto opacity-75 transition-opacity duration-500 group-hover:opacity-100"
            />
            <p
              class="mt-2 text-lg text-gray-400 transition-all duration-500 group-hover:text-gray-200"
            >
              {customer.name}
            </p>
          </a>
        {/each}
      </div>
    </div>
  </div>
  <div class="mt-12">
    <p class="text-xl font-bold">{$t('main.projects.title')}</p>
    <div class="grid lg:grid-cols-2 xl:grid-cols-3 gap-8 mt-4">
      {#each projects as project}
        <ProjectItem {...project} />
      {/each}
    </div>
  </div>
  <div
    class="bg-gray-700 px-6 py-4 mt-8 w-full rounded-sm lg:flex justify-between"
  >
    <p class="text-sm text-gray-400">
      {$t('main.footer')}
    </p>
    <a
      class="text-sm text-gray-400 underline transition-colors duration-300 hover:text-gray-200"
      href="mailto:paul.cardenas.0002@gmail.com"
      >paul.cardenas.0002@gmail.com
    </a>
  </div>
</div>
