<script>
  export let index,
    title,
    url = '',
    content,
    dateRange,
    lines;
</script>

<div class={`${index > 0 ? 'mt-4' : ''} flex group`}>
  <div class="bg-gray-700 p-6 relative flex-1 rounded-sm shadow-sm">
    <div class="flex flex-col sm:flex-row justify-between items-center">
      {#if url}
        <a
          href={url}
          target="_blank"
          rel="noreferrer"
          class="items-center hover:text-blue-500 hover:underline cursor-pointer flex gap-2"
        >
          <p class="font-bold">{title}</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-4 h-4"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
            />
          </svg>
        </a>
      {:else}
        <p class="font-bold">{title}</p>
      {/if}
      <div
        class="bg-gray-800 px-4 py-1 mt-1 sm:mt-0 rounded-full text-xs text-center text-gray-400"
      >
        {dateRange}
      </div>
    </div>
    <p class="text-sm text-gray-400 mt-2">
      {@html content}
    </p>
    <ul class="list-disc text-sm text-gray-400 pl-4">
      {#each lines as line}
        <li>{line}</li>
      {/each}
    </ul>
    <div class="absolute bg-gray-700 top-2 -right-1 h-3 w-3 rotate-45" />
  </div>
  <div class="relative w-10">
    <div class="w-1 h-full bg-gray-700 absolute right-2 top-[1.375rem]" />
    <span class="flex h-4 w-4 absolute right-0.5 top-1.5">
      <span
        class="group-hover:animate-ping absolute inline-flex h-4 w-4 rounded-full bg-primary-400 opacity-75"
      />
      <span class="relative inline-flex rounded-full h-4 w-4 bg-primary-500" />
      <span
        class="absolute inline-flex rounded-full h-2 w-2 top-1 left-1 bg-gray-900"
      />
    </span>
  </div>
</div>
