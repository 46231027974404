export default {
    en: {
        sidebar: {
            description: 'Web and Mobile Full-Stack developer',
            downloadCV: 'DOWNLOAD CV',
            cvUrl: '/docs/cv_english.pdf',
            details: {
                residence: 'Residence',
                city: 'City',
                age: 'Age',
            },
            languages: {
                spanish: 'Spanish',
                english: 'English',
            },
        },
        main: {
            greeting: {
                title: 'Welcome to my portfolio!',
                tag: 'greeting',
                start: "hi, I'm",
                end: 'Paul. I build websites and apps',
            },
            history: {
                title: 'Work history',
                cards: [
                    {
                        title: 'Toptal',
                        dateRange: 'November 2022 - Now',
                        content: 'Web and mobile development with React, Next.js, Typescript and React Native.',
                        lines: [
                            'Current Client - Revamping of legacy UI with a new design covering reports, dynamic tables, multi-team account settings, and others. Additionally built new features such as an approvals system chat, onboarding guide and  a browser extension. Using React and JavaScript.',
                            'BCG Gamma - Collaborated with a specialized team to enhance workflows by developing and optimizing internal tools using Next.js and TypeScript.',
                            'ClassDojo - Built features for Android using React Native to improve the current experience.',
                        ],
                    },
                    {
                        title: 'TimeJobs',
                        dateRange: 'June 2021 - October 2022',
                        content: 'Web and mobile development with Next.js and React Native.<br/>The project uses Redux Toolkit, Cypress, i18next for localization and Ant design for the web base components.',
                        lines: [
                            'Led the development of a robust web system using React, enabling efficient data management and seamless data display for a commercial app and reports.',
                            'Developed a hybrid app empowering users to apply for jobs, monitor attendance, provide ratings, and acquire new job-related skills.',
                        ],
                    },
                    {
                        title: 'Freelance',
                        dateRange: 'January 2020 - June 2021',
                        content: 'Custom web and mobile development with ReactJS, NodeJS and MongoDB.<br/>Using AWS services as main provider.<br/>Implemented features include tables with filters and pagination, multi-step forms, user roles management, etc.',
                        lines: [
                            'Necsa (Blog)',
                            'Cedhinfo (Internal web system)',
                            'Mypefact ERP',
                            'EDTLAB CMS and intranet',
                            'Real state landing pages with CMS',
                        ],
                    },
                    {
                        title: 'Mindtec',
                        dateRange: 'January 2017 - December 2019',
                        content: 'Web development with (Backend) NodeJs, Laravel and (Frontend) Pug, ReactJS.<br/>Mobile development using Java, Kotlin and Flutter.<br/>Database design NoSQL(MongoDB).<br/>AWS Services (S3, SES, EC2, CloudFront) and Firebase Services.',
                        lines: [
                            'Entel (Areas: TroubleTickets, InBuilding)',
                            'aComo Exchange house',
                            'CashIn',
                            'Billing service',
                            'Mypefact ERP',
                            'Cedhinfo (Internal web system)',
                            'Sportbalon (Complete web with internal system and app)',
                            'Educatronics (Courses manager)',
                            'Invictos (Educational app)',
                        ],
                    },
                ],
            },
            customers: [
                'TimeJobs',
                'TimeJobs Jobber',
                'Edt.Lab',
                'Cigol',
                'Acomo',
                'Entel',
                'Mindtec Billing',
                'Sportbalon',
                'Toptal',
                'BCG Gamma',
                'ClassDojo',
            ],
            projects: {
                title: 'Personal Projects',
                button: 'EXPLORE',
                cards: [
                    {
                        title: 'CMS',
                        content: 'CMS project to be used as a blog content API with categories and rich text editor support.<br/>Built with NodeJS, PugJS, AlpineJS and Tailwind CSS.',
                    },
                    {
                        title: 'Projects Manager',
                        content: 'Projects manager: quotation and progress tracker. I used it for managing my freelance projects and trying out some features.<br/>Built with NodeJS, ReactJS and Tailwind CSS.',
                    },
                    {
                        title: 'CRM',
                        content: 'Customer and payments manager. Part of a planned full ERP.<br/>Built with NodeJS, ReactJS and Tailwind CSS.<br/>Username: 12345678<br/>Password: 123456',
                    },
                    // {
                    //   title: 'GGO Android Launcher',
                    //   content:
                    //     'Android launcher heavily inspired by the SAO anime GGO interface design.<br/>It features apps management, visual settings and a social sharing space.<br/>Built with NodeJS and Flutter.',
                    // },
                    {
                        title: 'SAO Launcher',
                        content: 'Android launcher inspired by the SAO anime interface design.<br/>It has apps management, a Twitter like social network and game-like features.<br/>Uses RevenueCat for managing paid subscriptions.<br/>Built with NodeJS and Flutter.',
                    },
                ],
            },
            footer: '©2024 Built with SvelteJS and Tailwind CSS (And much ❤)',
        },
    },
    es: {
        sidebar: {
            description: 'Desarrollador full-stack Web y Mobile',
            downloadCV: 'DESCARGAR CV',
            cvUrl: '/docs/cv_spanish.pdf',
            details: {
                residence: 'País',
                city: 'Ciudad',
                age: 'Edad',
            },
            languages: {
                spanish: 'Español',
                english: 'Inglés',
            },
        },
        main: {
            greeting: {
                title: 'Bienvenido a mi espacio!',
                tag: 'greeting',
                start: 'hola, soy',
                end: 'Paul. Construyo páginas web y apps',
            },
            history: {
                title: 'Historial de trabajo',
                cards: [
                    {
                        title: 'Toptal',
                        dateRange: 'Noviembre 2022 - Ahora',
                        content: 'Desarrollo web y móvil con Next.js, Typescript y React Native.',
                        lines: [
                            'BCG Gamma - Colaboré con un equipo especializado para mejorar los flujos de trabajo desarrollando y optimizando herramientas internas usando Next.js y TypeScript.',
                            'ClassDojo - Desarrollo de funciones para la aplicación Android usando React Native.',
                        ],
                    },
                    {
                        title: 'TimeJobs',
                        dateRange: 'Junio 2021 - Octubre 2022',
                        content: 'Desarrollo web y móvil con Next.js y React Native.<br/>Dirijo mi equipo de desarrollo frontend trabajando junto con diseñadores y pruebas de control de calidad.<br/>El proyecto utiliza Redux Toolkit, Cypress, i18next para la localización y diseño Ant para los componentes de la base web.',
                        lines: ['Sistemas web internos', 'App híbrida (iOS y Android)'],
                    },
                    {
                        title: 'Freelance',
                        dateRange: 'Enero 2020 - Junio 2021',
                        content: 'Desarrollo web y móvil personalizado con ReactJS, NodeJS y MongoDB.<br/>Uso de los servicios de AWS como proveedor principal.<br/>Las características implementadas incluyen tablas con filtros y paginación, formularios de varios pasos, gestión de roles de usuario, etc.',
                        lines: [
                            'Necsa (Blog)',
                            'Cedhinfo (Sistema web interno)',
                            'Mypefact ERP',
                            'EDTLAB CMS y Aula virtual',
                            'Landing pages de inmobiliarias con CMS',
                        ],
                    },
                    {
                        title: 'Mindtec',
                        dateRange: 'Enero 2017 - Diciembre 2019',
                        content: 'Desarrollo web con (Backend) NodeJs, Laravel y (Frontend) Pug, ReactJS.<br/>Desarrollo móvil usando Java, Kotlin y Flutter.<br/>Diseño de base de datos NoSQL(MongoDB).<br/>Servicios AWS (S3, SES, EC2, CloudFront) y Firebase Services.',
                        lines: [
                            'Entel (Areas: TroubleTickets, InBuilding)',
                            'aComo: Casa de cambios virtual',
                            'CashIn',
                            'Sistema de facturación',
                            'Mypefact ERP',
                            'Cedhinfo (Sistema web interno)',
                            'Sportbalon (Web completa con sistema interno y app)',
                            'Educatronics (Gestor de cursos)',
                            'Invictos (App educativa)',
                        ],
                    },
                ],
            },
            customers: [
                'TimeJobs',
                'TimeJobs Jobber',
                'Edt.Lab',
                'Cigol',
                'Acomo',
                'Entel',
                'Mindtec Facturación',
                'Sportbalon',
                'Toptal',
                'BCG Gamma',
                'ClassDojo',
            ],
            projects: {
                title: 'Proyectos Personales',
                button: 'EXPLORAR',
                cards: [
                    {
                        title: 'CMS',
                        content: 'Gestor utilizado como una API de contenido de blog con categorías y editor de texto enriquecido.<br/>Construido con NodeJS, PugJS, AlpineJS y Tailwind CSS.',
                    },
                    {
                        title: 'Projects Manager',
                        content: 'Gestor de proyectos: seguimiento de cotizaciones y avances. Lo usé para administrar mis proyectos independientes y probar algunas funciones.<br/>Creado con NodeJS, ReactJS y Tailwind CSS.',
                    },
                    {
                        title: 'CRM',
                        content: 'Gestor de clientes y pagos. Parte de un ERP completo planificado.<br/>Construido con NodeJS, ReactJS y Tailwind CSS.<br/>Usuario: 12345678<br/>Contraseña: 123456',
                    },
                    // {
                    //   title: 'GGO Android Launcher',
                    //   content:
                    //     'Android Launcher inspirado en el diseño de interfaz GGO del anime SAO.<br/>Cuenta con administración de aplicaciones, configuraciones visuales y un espacio para compartir en redes sociales.<br/>Creado con NodeJS y Flutter.',
                    // },
                    {
                        title: 'SAO Launcher',
                        content: 'Custom Android Launcher inspirado en el diseño de la interfaz del anime SAO.<br/>Cuenta con administración de aplicaciones, una red social a lo Twitter y funciones gamificadas.<br/>Creado con NodeJS y Flutter.',
                    },
                ],
            },
            footer: '©2024 Construido con SvelteJS y Tailwind CSS (Y mucho ❤)',
        },
    },
};
