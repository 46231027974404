<script>
  import { t } from '../i18n';
  export let title, content, imageUrl, url;
</script>

<div class="bg-gray-700 rounded-sm shadow-sm flex flex-col group">
  <div class="h-36 overflow-hidden rounded-t-sm">
    <a href={url} target="_blank">
      <img
        class={`w-full h-36 object-center transition-all duration-500 group-hover:scale-110 ${
          title === 'SAO Launcher' ? 'object-contain' : 'object-cover'
        }`}
        src={imageUrl}
        alt={title}
      />
    </a>
  </div>
  <div class="p-6 flex-1 flex flex-col justify-between">
    <div>
      <p class="font-bold">{title}</p>
      <p class="text-sm text-gray-400 my-4">
        {@html content}
      </p>
    </div>
    <a
      class="text-sm text-primary-500 font-bold transition-all duration-500 group-hover:text-base"
      href={url}
      target="_blank"
    >
      {$t('main.projects.button')} ->
    </a>
  </div>
</div>
