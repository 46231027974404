<script lang="ts">
  import queryString from 'query-string';

  import Sidebar from './sections/Sidebar.svelte';
  import Main from './sections/Main.svelte';
  import { locale } from './i18n';

  const parsed = queryString.parse(window.location.search);
  locale.update(() =>
    ['en', 'es'].includes(parsed?.lang?.toString())
      ? parsed?.lang?.toString()
      : 'en'
  );
</script>

<main class="md:flex text-gray-200 font-sans">
  <Sidebar />
  <Main />
</main>

<style lang="postcss" global>
  @tailwind base;
  @tailwind components;
  @tailwind utilities;
  .scrollbar::-webkit-scrollbar {
    @apply w-2;
  }
  .scrollbar::-webkit-scrollbar-thumb {
    @apply rounded-2xl bg-gray-600;
  }
  .scrollbar::-webkit-scrollbar-thumb:active {
    @apply bg-gray-800;
  }
  .scrollbar::-webkit-scrollbar-thumb:hover {
    @apply bg-gray-700 shadow-lg transition-colors;
  }

  .scrollbar::-webkit-scrollbar-track {
    @apply rounded-md bg-gray-800;
  }
  .scrollbar::-webkit-scrollbar-track:hover,
  .scrollbar::-webkit-scrollbar-track:active {
    @apply bg-gray-800;
  }

  .cursor::after {
    @apply block w-1 h-6 bg-white;
    content: '';
    animation: cursor 0.6s linear infinite alternate;
    will-change: opacity;
  }

  @keyframes cursor {
    0%,
    40% {
      opacity: 1;
    }

    60%,
    100% {
      opacity: 0;
    }
  }
</style>
