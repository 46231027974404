<script lang="ts">
  import { t, locale, locales } from '../i18n';
  import SkillItem from '../components/SkillItem.svelte';

  const skills = [
    'React, Next.js, Svelte',
    'React Native',
    'Cypress, Jest',
    'CSS, Tailwind CSS',
    'NodeJS (Express, Koa)',
    'MongoDB',
    'AWS Services',
    'Flutter',
    'Kotlin, Java',
    'PHP, Laravel',
  ];
  const extraSkills = [
    'English (Advanced)',
    'Git',
    'Photoshop',
    'Illustrator',
    'Office',
  ];

  function calculateAge(birthday) {
    const ageDifMs = Date.now() - birthday;
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }
</script>

<div class="md:w-1/3 xl:w-1/5 bg-gray-800 flex flex-col md:h-screen shadow-md">
  <div class="md:overflow-y-scroll flex-1 scrollbar scroll-smooth">
    <div class="bg-gray-700 shadow-sm w-full text-center p-8 relative">
      <div
        class="mx-auto w-24 h-24 border-primary-200 bg-primary-200 rounded-full relative"
      >
        <img src="/favicon.png" alt="That's me" class="rounded-full" />
        <span class="flex h-4 w-4 absolute right-1 bottom-1">
          <span
            class="animate-ping absolute inline-flex h-4 w-4 rounded-full bg-primary-400 opacity-75"
          />
          <span
            class="relative inline-flex rounded-full h-4 w-4 bg-primary-500"
          />
        </span>
      </div>
      <select
        name="location"
        bind:value={$locale}
        class="absolute top-2 right-2 block px-2 py-2 text-base text-gray-600 border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md"
      >
        {#each locales as l}
          <option value={l}>{l.toLocaleUpperCase()}</option>
        {/each}
      </select>
      <h1 class="text-lg font-bold mt-3">Paul Cardenas</h1>
      <a
        class="text-sm text-gray-400 underline transition-colors duration-300 hover:text-gray-200"
        href="mailto:paul.cardenas.0002@gmail.com"
        >paul.cardenas.0002@gmail.com</a
      >
      <p class="text-sm text-gray-400 mt-3">
        {$t('sidebar.description')}
      </p>
      <div class="mt-4 flex justify-center">
        <a
          class="flex items-center space-x-2 text-sm text-gray-400 font-bold transition-colors duration-300 hover:text-gray-200"
          href={$t('sidebar.cvUrl')}
          target="_blank"
        >
          <span>{$t('sidebar.downloadCV')}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
            />
          </svg>
        </a>
      </div>
    </div>
    <div class="py-4 px-6 flex flex-col space-y-6 divide-y divide-gray-600">
      <div>
        <div class="py-1 flex justify-between">
          <dt class="text-sm font-medium">
            {$t('sidebar.details.residence')}:
          </dt>
          <dd class="text-sm text-gray-400">Peru</dd>
        </div>
        <!-- <div class="py-1 flex justify-between">
          <dt class="text-sm font-medium">{$t('sidebar.details.city')}:</dt>
          <dd class="text-sm text-gray-400">Huancayo</dd>
        </div> -->
        <div class="py-1 flex justify-between">
          <dt class="text-sm font-medium">{$t('sidebar.details.age')}:</dt>
          <dd class="text-sm text-gray-400">
            {calculateAge(new Date(2000, 8, 11, 0, 0, 0, 0))}
          </dd>
        </div>
      </div>
      <div class="py-6 px-4 flex justify-around">
        <div class="relative w-16 h-16">
          <svg viewBox="0 0 100 100" style="display: block; width: 100%;">
            <path
              d="M 50,50 m 0,-46.5 a 46.5,46.5 0 1 1 0,93 a 46.5,46.5 0 1 1 0,-93"
              class="stroke-primary-500"
              stroke-width="7"
              fill-opacity="0"
            />
            <path
              d="M 50,50 m 0,-46.5 a 46.5,46.5 0 1 1 0,93 a 46.5,46.5 0 1 1 0,-93"
              class="stroke-primary-500"
              stroke-width="7"
              fill-opacity="0"
              style="stroke-dasharray: 292.273, 292.273; stroke-dashoffset: 29.2273;"
            />
          </svg>
          <div
            class="absolute top-0 h-full w-full flex items-center justify-center text-center"
          >
            <p class="text-sm text-gray-400">Native</p>
          </div>
          <p class="text-center text-sm mt-2">
            {$t('sidebar.languages.spanish')}
          </p>
        </div>
        <div class="relative w-16 h-16">
          <svg viewBox="0 0 100 100" style="display: block; width: 100%;"
            ><path
              d="M 50,50 m 0,-46.5 a 46.5,46.5 0 1 1 0,93 a 46.5,46.5 0 1 1 0,-93"
              class="stroke-primary-900"
              stroke-width="7"
              fill-opacity="0"
            /><path
              d="M 50,50 m 0,-46.5 a 46.5,46.5 0 1 1 0,93 a 46.5,46.5 0 1 1 0,-93"
              class="stroke-primary-500"
              stroke-width="7"
              fill-opacity="0"
              style="stroke-dasharray: 292.273, 292.273; stroke-dashoffset: 29.2273;"
            /></svg
          >
          <div
            class="absolute top-0 h-full w-full flex items-center justify-center text-center"
          >
            <p class="text-sm text-gray-400">C2</p>
          </div>
          <p class="text-center text-sm mt-2">
            {$t('sidebar.languages.english')}
          </p>
        </div>
      </div>
      <div class="pt-6 px-4">
        {#each skills as skill, i}
          <SkillItem name={skill} index={i} />
        {/each}
      </div>
      <div class="py-6 px-4">
        {#each extraSkills as skill, i}
          <SkillItem name={skill} index={i} />
        {/each}
      </div>
    </div>
  </div>
  <div
    class="bg-gray-700 shadow-sm w-full text-center flex justify-around py-3"
  >
    <a href="https://linkedin.com/in/Zegaru" target="_blank">
      <img src="/img/linkedin.svg" alt="Linkedin logo" class="h-5 w-5" />
    </a>
    <a href="https://github.com/Zegaru" target="_blank">
      <img src="/img/github.svg" alt="Github logo" class="h-5 w-5" />
    </a>
    <a href="https://gitlab.com/Zegaru" target="_blank">
      <img src="/img/gitlab.svg" alt="Gitlab logo" class="h-5 w-5" />
    </a>
  </div>
</div>
