import { derived, writable } from 'svelte/store';
import translations from './translations';
export const locale = writable('en');
export const locales = Object.keys(translations);
function translate(locale, key, vars) {
    // Let's throw some errors if we're trying to use keys/locales that don't exist.
    // We could improve this by using Typescript and/or fallback values.
    if (!key)
        throw new Error('no key provided to $t()');
    if (!locale)
        throw new Error(`no translation for key "${key}"`);
    // Grab the translation from the translations object.
    const keys = key.split('.');
    let translationAux = translations[locale];
    for (let i = 0; i < keys.length; i++) {
        translationAux = translationAux[keys[i]];
        if (!translationAux)
            return `No translation found for ${locale}.${key}`;
    }
    if (typeof translationAux !== 'string')
        return `No translation found for ${locale}.${key}`;
    // Replace any passed in variables in the translation string.
    Object.keys(vars).map((k) => {
        const regex = new RegExp(`{{${k}}}`, 'g');
        translationAux = translationAux.replace(regex, vars[k]);
    });
    return translationAux;
}
export const t = derived(locale, ($locale) => (key, vars = {}) => translate($locale, key, vars));
