<script>
  export let index, name;
</script>

<p class={`${index > 0 ? 'mt-2' : ''} flex items-center text-sm`}>
  <svg
    class="flex-shrink-0 mr-1.5 h-5 w-5 text-primary-400"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
    aria-hidden="true"
  >
    <path
      fill-rule="evenodd"
      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
      clip-rule="evenodd"
    />
  </svg>
  {name}
</p>
